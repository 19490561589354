import * as React from 'react';
import { graphql, Link } from 'gatsby';
import LayoutConsumer from '../components/layout-context';
import Seo from '../components/seo';
import InfoBox from '../components/info-box';
import FancyTitle from '../components/fancy-title';
import References from '../components/references';
import PipedriveForm from '../components/pipedrive-form';

function K5Page({ data }) {
  const page = data.allK5Yaml.nodes[0];
  return (
    <LayoutConsumer>
      {({ set }) => {
        set({ pageTitle: 'K5', fullWidth: true });
        return (
          <>
            <InfoBox data={{ title: page.title, image: page.featured_image }} />
            <div className="px-4 lg:px-16 pt-20 lg:pt-36">
              <Seo
                title={page.meta_title}
                description={page.meta_description}
              />
              <FancyTitle
                text={page.h1}
                subtitle={page.subtitle}
                className="pt-4 lg:pt-8 text-center"
                tag="h1"
              />

              <p
                className="text-center"
                dangerouslySetInnerHTML={{ __html: page.content }}
              />

              <div className="grid grid-cols-2 xl:grid-cols-4 mt-10">
                {page.facts.map((fact, index) => (
                  <div
                    className={`p-2 flex flex-wrap xl:flex-nowrap justify-center items-center ${
                      index < page.facts.length - 1
                        ? 'xl-border-r-[inherit] xl:dark-border-r-white xl:border-r-2'
                        : ''
                    }`}
                    key={fact.value}
                  >
                    <span className="basis-full xl:basis-auto text-center xl:text-left font-bold text-latori-green text-5xl xl:mr-4">
                      {fact.value}
                    </span>
                    <span
                      className="basis-full xl:basis-auto text-center xl:text-left w-32"
                      dangerouslySetInnerHTML={{ __html: fact.label }}
                    />
                  </div>
                ))}
              </div>
              <div className="flex flex-col items-center mt-10">
                <p>{page.facts_subtitle}</p>
                {page.ctaUrl && (
                  <Link to={page.ctaUrl} className="button-primary mt-6">
                    {page.ctaLabel}
                  </Link>
                )}
              </div>
              {page.giveAwayTitle && page.giveAwayForm && (
                <>
                  <FancyTitle
                    text={page.giveAwayTitle}
                    className="pt-20 lg:pt-36 text-center"
                    tag="h2"
                  />
                  <div
                    className="pipedriveWebForms k5-form"
                    dangerouslySetInnerHTML={{
                      __html:
                        page.giveAwayForm,
                    }}
                  />
                </>
              )}
              <FancyTitle
                text={page.teamTitle}
                className="pt-20 lg:pt-36 text-center"
                tag="h2"
              />
              <p className="text-center mt-10">{page.teamText}</p>

              <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 mt-10">
                {page.team.map((member) => (
                  <div className="flex flex-col items-center" key={member.name}>
                    <div className="w-[100px] h-[100px] xl:w-[200px] xl:h-[200px] rounded-full overflow-hidden">
                      <img
                        src={
                          member.image.childImageSharp.gatsbyImageData.images
                            .fallback.src
                        }
                        alt={member.name}
                        className="w-full h-full object-cover"
                      />
                    </div>
                    <p className="pt-4 lg:pt-8 mb-1 text-xl max-w-min md:max-w-none text-center">
                      {member.name}
                    </p>
                    <p className="text-center mb-5">{member.position}</p>
                  </div>
                ))}
              </div>

              <FancyTitle
                text={page.findUs.title}
                className="pt-20 lg:pt-36 text-center"
                tag="h2"
              />
              <div className="flex flex-col items-center mt-10">
                <p>{page.findUs.intro}</p>
                <div className="w-[100%] lg:w-[800px] mt-20 mb-20">
                  <img
                    src={
                      page.findUs.image.childImageSharp.gatsbyImageData.images
                        .fallback.src
                    }
                    alt={page.findUs.title}
                    className="w-full h-full object-cover"
                  />
                </div>
                <p dangerouslySetInnerHTML={{ __html: page.findUs.text }} />
                {page.ctaUrl && (
                  <Link to={page.ctaUrl} className="button-primary mt-6">
                    {page.ctaLabel}
                  </Link>
                )}
              </div>
              {page.masterclass.title && page.masterclass.info && (
                <div className="mt-[100px] lg:mt-36">
                  <FancyTitle
                    text={page.masterclass.title}
                    className="text-center"
                    tag="h2"
                  />

                  <p
                    className="text-center"
                    dangerouslySetInnerHTML={{ __html: page.masterclass.info }}
                  />
                  {page.masterclass.date && (
                    <p className="text-center mt-6 ">
                      <b>Wann: </b>
                      {page.masterclass.date}
                    </p>
                  )}

                  {page.masterclass.time && (
                    <p className="text-center  mt-3 ">
                      <b>Uhrzeit: </b>
                      {page.masterclass.time}
                    </p>
                  )}
                  {page.masterclass.where && (
                    <p className="text-center  mt-3 ">
                      <b>Wo: </b>
                      {page.masterclass.where}
                    </p>
                  )}
                  {page.masterclass.speaker && (
                    <p className="text-center mt-3 ">
                      <b>Speaker: </b>
                      <p
                        className="text-center inline"
                        dangerouslySetInnerHTML={{ __html: page.masterclass.speaker }}
                      />
                    </p>
                  )}
                  {page.masterclass.category && (
                    <p className="text-center mt-3 ">
                      <b>Kategorie: </b>
                      {page.masterclass.category}
                    </p>
                  )}
                </div>
              )}
              <div className="mt-[100px] lg:mt-36">
                <References
                  title="Über 10 Jahre Erfahrung, über 100 Enterprise-Projekte"
                  subtitle="Ein Auszug unserer Referenzen"
                  textAlign="text-center lg:text-center"
                  titleTag="h2"
                  referencesTitleTag="h3"
                  limit={15}
                />
              </div>
              <div className="mt-[100px] lg:mt-36">
                <PipedriveForm
                  text={page.contactUs}
                  title={page.contactUsTitle}
                />
              </div>
            </div>

            <div id="termsandconditions" className="px-4 lg:px-16">
              <FancyTitle
                text={page.conditionsTitle}
                className="pt-20 lg:pt-36 text-center"
                tag="h2"
              />
              {page.veranstalter && page.veranstalterText && (
                <>
                  <p className="text-center mt-10 font-bold">
                    {page.veranstalter}
                  </p>
                  <p
                    className="text-center"
                    dangerouslySetInnerHTML={{ __html: page.veranstalterText }}
                  />
                </>
              )}

              {page.berechtigung && page.berechtigungText && (
                <>
                  <p className="text-center mt-10 font-bold">
                    {page.berechtigung}
                  </p>
                  <p
                    className="text-center"
                    dangerouslySetInnerHTML={{ __html: page.berechtigungText }}
                  />
                </>
              )}

              {page.teilnahmebedingungen && page.teilnahmebedingungenText && (
                <>
                  <p className="text-center mt-10 font-bold">
                    {page.teilnahmebedingungen}
                  </p>
                  <p
                    className="text-center"
                    dangerouslySetInnerHTML={{
                      __html: page.teilnahmebedingungenText,
                    }}
                  />
                </>
              )}

              {page.gewinnspielzeitraum && page.gewinnspielzeitraumText && (
                <>
                  <p className="text-center mt-10 font-bold">
                    {page.gewinnspielzeitraum}
                  </p>
                  <p
                    className="text-center"
                    dangerouslySetInnerHTML={{
                      __html: page.gewinnspielzeitraumText,
                    }}
                  />
                </>
              )}

              {page.gewinne && page.gewinneText && (
                <>
                  <p className="text-center mt-10 font-bold">{page.gewinne}</p>
                  <p
                    className="text-center"
                    dangerouslySetInnerHTML={{ __html: page.gewinneText }}
                  />
                </>
              )}

              {page.ermittlung && page.ermittlungText && (
                <>
                  <p className="text-center mt-10 font-bold">
                    {page.ermittlung}
                  </p>
                  <p
                    className="text-center"
                    dangerouslySetInnerHTML={{ __html: page.ermittlungText }}
                  />
                </>
              )}

              {page.datenschutz && page.datenschutzText && (
                <>
                  <p className="text-center mt-10 font-bold">
                    {page.datenschutz}
                  </p>
                  <p
                    className="text-center"
                    dangerouslySetInnerHTML={{ __html: page.datenschutzText }}
                  />
                </>
              )}

              {page.rechtsweg && page.rechtswegText && (
                <>
                  <p className="text-center mt-10 font-bold">
                    {page.rechtsweg}
                  </p>
                  <p
                    className="text-center"
                    dangerouslySetInnerHTML={{ __html: page.rechtswegText }}
                  />
                </>
              )}

              {page.aenderung && page.aenderungText && (
                <>
                  <p className="text-center mt-10 font-bold">
                    {page.aenderung}
                  </p>
                  <p
                    className="text-center"
                    dangerouslySetInnerHTML={{ __html: page.aenderungText }}
                  />
                </>
              )}

              {page.sonstiges && page.sonstigesText && (
                <>
                  <p className="text-center mt-10 font-bold">
                    {page.sonstiges}
                  </p>
                  <p
                    className="text-center"
                    dangerouslySetInnerHTML={{ __html: page.sonstigesText }}
                  />
                </>
              )}

              {page.kontakt && page.kontaktText && (
                <>
                  <p className="text-center mt-10 font-bold">{page.kontakt}</p>
                  <p
                    className="text-center"
                    dangerouslySetInnerHTML={{ __html: page.kontaktText }}
                  />
                </>
              )}
            </div>
            <div className="my-24 lg:my-36" />
          </>
        );
      }}
    </LayoutConsumer>
  );
}

export default K5Page;

export const k5Query = graphql`
  query {
    allK5Yaml {
      nodes {
        title
        h1
        meta_title
        meta_description
        subtitle
        content
        facts {
          label
          value
        }
        facts_subtitle
        ctaUrl
        ctaLabel
        findUs {
          intro
          title
          text
          image {
            childImageSharp {
              gatsbyImageData(
                width: 1200
                placeholder: BLURRED
                formats: [PNG, AUTO, WEBP, AVIF]
              )
            }
          }
        }
        giveAwayTitle
        giveAwayForm
        contactUs
        contactUsTitle
        teamTitle
        teamText
        team {
          name
          position
          image {
            childImageSharp {
              gatsbyImageData(
                width: 300
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF, PNG]
              )
            }
          }
        }
        featured_image {
          childImageSharp {
            gatsbyImageData(
              width: 800
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        conditionsTitle
        veranstalter
        veranstalterText
        berechtigung
        berechtigungText
        teilnahmebedingungen
        teilnahmebedingungenText
        gewinnspielzeitraum
        gewinnspielzeitraumText
        gewinne
        gewinneText
        ermittlung
        ermittlungText
        datenschutz
        datenschutzText
        rechtsweg
        rechtswegText
        aenderung
        aenderungText
        sonstiges
        sonstigesText
        kontakt
        kontaktText
         masterclass {
          title
          info
          date
          time
          where
          speaker
          category
        }
      }
    }
  }
`;
